import {Alert, PageHeader} from "antd";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function Support() {
    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Support"
            />

            <Alert style={{
                marginBottom: "25px"
            }}
                   message={"Bientôt disponible"}
                   description="Liste des demandes de support ouvertes par defaut avec échanges interactifs." type="info" showIcon />
        </>
    )
}

export default Support;

