/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
import {Button, Drawer, Form, Input, Select, Space} from "antd";
import {useEffect} from "react";
import {Project} from "../api/project";
import {useQuery} from "@tanstack/react-query";
import {Company} from "../api/company";

const {Option} = Select;

function ProjectDrawer(props) {
    const {data: compagnies, isLoading: compagniesIsLoading} = useQuery(['compagnies'], Company.getAll, {
        initialData: []
    });

    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
        if (props.projectId) {
            Project.getOne(props.projectId).then(project => {
                form.setFieldsValue({
                    name: project.name,
                    company_id: project.company_id,
                })
            });
        }
    }, [props.visible, props.projectId])

    const onSaveDrawerHandler = async () => {
        let payload = form.getFieldsValue();

        let isValid = await form.validateFields();

        if (isValid) {
            if (!props.projectId || props.projectId === null) {
                Project.create(payload).then(() => {
                    props.onSave();
                });
            } else {
                Project.update(props.projectId, payload).then(() => {
                    props.onSave();
                });
            }
        }
    }

    const title = () => {
        if (!props.projectId || props.projectId === null) {
            return "Ajout d'un nouveau projet";
        } else {
            return "Édition d'un projet";
        }
    }

    const formItemRules = [
        {
            required: true,
            message: 'Champ obligatoire',
        }
    ]

    return (
        <>
            <Drawer title={title()} placement="left" onClose={props.onClose}
                    visible={props.visible} width={700} extra={
                <Space>
                    <Button onClick={props.onClose}>Annuler</Button>
                    <Button onClick={onSaveDrawerHandler} type="primary">
                        Enregistrer
                    </Button>
                </Space>
            }>
                <Form
                    form={form}
                    layout="vertical"
                >
                    <Form.Item name="company_id" label="Entreprise" rules={formItemRules} tooltip="This is a required field">
                        <Select
                            showSearch
                            placeholder="Sélectionnez une entreprise"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option.children.toLowerCase().includes(input.toLowerCase()))
                            }
                        >
                            {compagnies.map(company => (
                                <Option value={company._id}>{company.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="name" label="Nom"  rules={formItemRules} tooltip="This is a required field">
                        <Input/>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    )
}

export default ProjectDrawer;
