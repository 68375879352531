import {
    Alert,
    Button,
    Card,
    Col,
    Divider,
    Form,
    Input,
    List,
    PageHeader,
    Row,
    Select, Statistic,
    Tabs,
    Tag,
    Typography
} from "antd";
import {useContext, useEffect, useState} from "react";
import {RepositoryReport} from "../../api/repositoryReport";

import {
    ExclamationCircleOutlined,
    CloseCircleOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined
} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {Project} from "../../api/project";
import {Repository} from "../../api/repository";
import {SelectedProjectContext} from "../../App";
import {RepositoryMetric} from "../../api/repositoryMetric";
import {DateTime} from "luxon";

const {Option} = Select;

function DependancesCommentsList(props) {
    return (
        <List
            bordered
            size={"small"}
            header={props.header}
            dataSource={props.comments.filter((item) => {
                if (props.principal) {
                    return item.package.in_manifest ? item : null;
                } else {
                    return !item.package.in_manifest ? item : null;
                }
            }).sort((a, b) => {
                return (a.type === 'danger') ? -1 : 1;
            })}
            renderItem={(item) => (
                <List.Item>
                    <Row>
                        <Col style={{width: "30px"}}>{item.type === 'danger' ?
                            <CloseCircleOutlined style={{color: "#ff4d4f"}}/> :
                            <ExclamationCircleOutlined style={{color: "#faad14"}}/>}</Col>
                        <Col><Tag color="default">{item.package.name}</Tag> {item.comment}</Col>
                    </Row>
                </List.Item>
            )}
        />
    )
}

function DependancesVulnerabilitesList(props) {
    return (
        <List
            bordered
            size={"small"}
            header={props.header}
            dataSource={props.data}
            renderItem={(item) => (
                <List.Item>
                    <Row>
                        <Col style={{width: "30px"}}>{item.severity !== 'MEDIUM' ?
                            <CloseCircleOutlined style={{color: "#ff4d4f"}}/> :
                            <ExclamationCircleOutlined style={{color: "#faad14"}}/>}</Col>
                        <Col><Tag color="default">{item.components[0].name}</Tag> <Tag
                            color="default">{item.components[0].version}</Tag> <a
                            href={'https://ossindex.sonatype.org/vuln/' + item.vulnId}>{item.vulnId}</a></Col>
                        <Col></Col>
                    </Row>
                </List.Item>
            )}
        />
    )
}

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function Observabilites() {

    const [formFilter] = Form.useForm();
    const {selectedProject, updateSelectedProject} = useContext(SelectedProjectContext);

    const [reportComments, setReportComments] = useState(null);
    const [metrics, setMetrics] = useState([]);
    const [selectedRepositoryId, setSelectedRepositoryId] = useState(null);

    const {
        data: repositories,
        isLoading: repositoriesIsLoading,
        refetch: refetchRepositories
    } = useQuery(['repositories'], () => {
        return Repository.getAll({
            q: {
                project_id: selectedProject._id
            }
        });
    }, {
        initialData: []
    });

    useEffect(() => {
        refetchRepositories();
    }, [selectedProject])

    useEffect(() => {
        if (repositories.length > 0) {
            setSelectedRepositoryId(repositories[0]._id);
            formFilter.setFieldsValue({
                repository_id: repositories[0]._id
            })
        }
    }, [repositories])

    useEffect(() => {
        if (selectedRepositoryId !== null) {
            RepositoryReport.getLastOneComments(selectedRepositoryId).then((comments) => {
                setReportComments(comments);
            }).catch(() => {
            });

            RepositoryMetric.getLast(selectedRepositoryId).then((metrics) => {
                setMetrics(metrics);
            }).catch(() => {
            });
        }
    }, [selectedRepositoryId])

    const findMetric = (name) => {
        for (let i in metrics) {
            if (metrics[i].name == name) {
                return metrics[i];
            }
        }

        return null;
    }

    let lines_metrics = findMetric('lines');
    lines_metrics = lines_metrics == null ? 0 : parseInt(lines_metrics['value']);

    let classes_metrics = findMetric('classes');
    classes_metrics = classes_metrics == null ? 0 : parseInt(classes_metrics['value']);

    let functions_metrics = findMetric('functions');
    functions_metrics = functions_metrics == null ? 0 : parseInt(functions_metrics['value']);

    let last_commit_metrics = findMetric('last_commit_date');
    last_commit_metrics = last_commit_metrics == null ? null :  DateTime.fromSeconds(parseInt(last_commit_metrics['value'])).toLocaleString(DateTime.DATETIME_FULL);

    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Observabilités"
            />
            <Form
                form={formFilter}
                name="basic"
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                style={{maxWidth: 600}}
                initialValues={{remember: true}}
                autoComplete="off"
            >
                <Form.Item
                    name="repository_id"
                >
                    <Select
                        showSearch
                        disabled={repositories.length == 0}
                        placeholder="Sélectionnez un repo"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option.children.toLowerCase().includes(input.toLowerCase()))
                        }
                        onChange={(v) => {
                            setSelectedRepositoryId(v);
                        }}
                    >
                        {repositories.map(projet => (
                            <Option value={projet._id}>{projet.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
            <Alert
                style={{margin: "0 0 20px 0"}}
                message="Ensemble de points d'intérets à observer pour chaque dépôt de code."
                type="info"
                showIcon
            />
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Statistiques" key="1">
                    <Row gutter={16}>
                        <Col span={6}>
                            <Card bordered={false}>
                                <Statistic
                                    title="Nombre de ligne de code"
                                    value={lines_metrics}
                                    valueStyle={{color: '#3f8600'}}
                                />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card bordered={false}>
                                <Statistic
                                    title="Nombre de classes"
                                    value={classes_metrics}
                                    valueStyle={{color: '#cf1322'}}
                                />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card bordered={false}>
                                <Statistic
                                    title="Nombre de fonctions"
                                    value={functions_metrics}
                                    valueStyle={{color: '#cf1322'}}
                                />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card bordered={false}>
                                <Statistic
                                    title="Dernier commit"
                                    value={last_commit_metrics}
                                    valueStyle={{color: '#cf1322'}}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Dépendances" key="2">
                    <Alert
                        style={{margin: "0 0 20px 0"}}
                        message="Liste des observations sur les dépendances externes qui meritent une attention."
                        type="info"
                        showIcon
                    />
                    <DependancesCommentsList comments={reportComments !== null ? reportComments.comments : []}
                                             header={<div>Dépendances principales</div>}
                                             principal/>
                    <Divider></Divider>
                    <DependancesCommentsList comments={reportComments !== null ? reportComments.comments : []}
                                             header={<div>Dépendances secondaires</div>}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Sécurité" key="3">
                    <Alert
                        style={{margin: "0 0 20px 0"}}
                        message="Vulnérabilités détectées par les communautés de sécurité. Toute mention dans cette partie mérite une attention accrue."
                        type="info"
                        showIcon
                    />
                    <DependancesVulnerabilitesList data={reportComments !== null ? reportComments.vulnerabilities : []}
                                                   header={<div>Vulnérabilités dans les dépendances</div>}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Monitoring" key="4">
                    <Alert
                        style={{margin: "0 0 20px 0"}}
                        message={'Bientôt disponible'}
                        description="Monitoring simplifié (statuts ok/nok) si TMA activé."
                        type="info"
                        showIcon
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Tests automatisés" key="5">
                    <Alert
                        style={{margin: "0 0 20px 0"}}
                        message={'Bientôt disponible'}
                        description="Résultat des derniers tests automatisés si activé."
                        type="info"
                        showIcon
                    />
                </Tabs.TabPane>
            </Tabs>
        </>
    )
}

export default Observabilites;

