import {Config} from "../config";
import {useAuth0} from "@auth0/auth0-react";
import {getHeaders} from "./auth";

let RepositoryReport = {

    /**
     *
     */
    getLastOne: async (repositoryId) => {
        let url = Config.apiUrl + '/repository/' + repositoryId + '/report';
        return fetch(url, {
            headers: getHeaders()
        }).then(res => res.json())
    },

    /**
     *
     */
    getLastOneComments: async (repositoryId) => {
        let url = Config.apiUrl + '/repository/' + repositoryId + '/report_comments';

        return fetch(url, {
            headers: getHeaders(),
        }).then(res => res.json())
    },
}

export {RepositoryReport};
