import {Config} from "../config";
import {getHeaders} from "./auth";

let Project = {

    /**
     *
     * @param projectId
     * @returns {Promise<any>}
     */
    getOne: async (projectId) => {
        let url = Config.apiUrl + '/project/' + projectId;
        return fetch(url, {
            headers: getHeaders()
        }).then(res => res.json())
    },

    /**
     *
     * @param payload
     * @returns {Promise<any>}
     */
    create: async (payload) => {
        let url = Config.apiUrl + '/project';
        return fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: getHeaders(),
            body: JSON.stringify(payload)
        }).then(res => res.json())
    },

    /**
     *
     * @param companyId
     * @param payload
     * @returns {Promise<any>}
     */
    update: async (companyId, payload) => {
        let url = Config.apiUrl + '/project/' + companyId;
        return fetch(url, {
            method: 'PUT',
            credentials: 'include',
            headers: getHeaders(),
            body: JSON.stringify(payload)
        }).then(res => res.json())
    },

    /**
     *
     * @param payload
     * @returns {Promise<any>}
     */
    getAll: async (payload) => {
        let url = Config.apiUrl + '/projects';
        return fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: getHeaders(),
            body: JSON.stringify(payload)
        }).then(res => res.json())
    },

}

export {Project};
