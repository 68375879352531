/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
import {Button, Drawer, Form, Input, Space} from "antd";
import {useEffect} from "react";
import {Company} from "../api/company";

function CompanyDrawer(props) {

    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
        if (props.companyId) {
            Company.getOne(props.companyId).then(company => {
                form.setFieldsValue({
                    name: company.name
                })
            });
        }
    }, [props.visible, props.companyId])

    const onSaveDrawerHandler = () => {
        let payload = form.getFieldsValue();

        if (!props.companyId || props.companyId === null) {
            Company.create(payload).then(() => {
                props.onSave();
            });
        } else {
            Company.update(props.companyId, payload).then(() => {
                props.onSave();
            });
        }
    }

    const title = () => {
        if (!props.companyId || props.companyId === null) {
            return "Ajout d'une nouvelle entreprise";
        } else {
            return "Édition d'une entreprise";
        }
    }

    return (
        <>
            <Drawer title={title()} placement="left" onClose={props.onClose}
                    visible={props.visible} width={700} extra={
                <Space>
                    <Button onClick={props.onClose}>Annuler</Button>
                    <Button onClick={onSaveDrawerHandler} type="primary">
                        Enregistrer
                    </Button>
                </Space>
            }>
                <Form
                    form={form}
                    layout="vertical"
                >
                    <Form.Item name="name" label="Nom" required tooltip="This is a required field">
                        <Input/>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    )
}

export default CompanyDrawer;
