import {Button, PageHeader, Space, Table} from "antd";
import {User} from "../../api/user";
import {useQuery} from "@tanstack/react-query";
import {useState} from "react";

import {
    PlusCircleOutlined,
    EyeOutlined
} from "@ant-design/icons";
import UserDrawer from "../../components/userDrawer";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function Users() {
    const {data: users, isLoading: usersIsLoading, refetch} = useQuery(['users'], User.getAll);
    const [userDrawerVisible, setUserDrawerVisible] = useState(false);
    const [userIdToEdit, setUserIdToEdit] = useState(null);

    const columns = [
        {
            title: 'Prénom',
            dataIndex: 'firstname'
        },
        {
            title: 'Nom',
            dataIndex: 'lastname'
        },
        {
            title: 'Email',
            dataIndex: 'email'
        },
        {
            key: 'action',
            width: 80,
            render: (_, record) => (
                <Space>
                    <Button shape="circle" icon={<EyeOutlined/>}
                            onClick={() => onEditUserHandler(record._id)}/>
                </Space>
            ),
        },
    ];

    let onEditUserHandler = (userId) => {
        setUserIdToEdit(userId);
        setUserDrawerVisible(true);
    }

    let onClickAddUserHandler = (e) => {
        setUserDrawerVisible(true);
    }

    let onSaveUserDrawerHandler = () => {
        refetch();
        closeUserDrawer();
    }

    let onCloseUserDrawerHandler = () => {
        closeUserDrawer();
    }

    let closeUserDrawer = () => {
        setUserIdToEdit(null);
        setUserDrawerVisible(false);
    }

    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Utilisateurs"
                extra={[
                    <Button shape="circle" icon={<PlusCircleOutlined/>} type="primary"
                            onClick={onClickAddUserHandler}/>
                ]}
            />

            <Table columns={columns} dataSource={users} loading={usersIsLoading}/>

            <UserDrawer visible={userDrawerVisible} onClose={onCloseUserDrawerHandler}
                           userId={userIdToEdit}
                           onSave={onSaveUserDrawerHandler}/>
        </>
    )
}

export default Users;

