import {Config} from "../config";
import {getHeaders} from "./auth";

let User = {

    /**
     *
     */
    getOne: async (userId) => {
        let url = Config.apiUrl + '/user/' + userId;
        return fetch(url, {
            headers: getHeaders()
        }).then(res => res.json())
    },

    /**
     *
     */
    me: async () => {
        let url = Config.apiUrl + '/me';
        let headers = getHeaders();
        return fetch(url, {headers}).then(res => res.json())
    },

    /**
     *
     */
    create: async (payload) => {
        let url = Config.apiUrl + '/user';
        return fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: getHeaders(),
            body: JSON.stringify(payload)
        }).then(res => res.json())
    },

    /**
     *
     */
    update: async (userId, payload) => {
        let url = Config.apiUrl + '/user/' + userId;
        return fetch(url, {
            method: 'PUT',
            credentials: 'include',
            headers: getHeaders(),
            body: JSON.stringify(payload)
        }).then(res => res.json())
    },

    /**
     *
     */
    getAll: async () => {
        let url = Config.apiUrl + '/users';
        return fetch(url, {
            headers: getHeaders()
        }).then(res => res.json())
    },

}

export {User};
