import {Button, Col, Form, Input, PageHeader, Row, Select, Space, Table} from "antd";
import {Project} from "../../api/project";
import {useQuery} from "@tanstack/react-query";
import {useState} from "react";

import {
    PlusCircleOutlined,
    EyeOutlined
} from "@ant-design/icons";
import ProjectDrawer from "../../components/projectDrawer";
import {useForm} from "antd/lib/form/Form";
import {Company} from "../../api/company";
import {Repository} from "../../api/repository";
import RepositoryDrawer from "../../components/repositoryDrawer";

const {Option} = Select;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function Repositories() {
    const [filterForm] = useForm();

    const {
        data: companies,
        isLoading: companiesIsLoading
    } = useQuery(['companies'], Company.getAll, {
        initialData: []
    });

    const {
        data: projects,
        isLoading: projectsIsLoading,
        refetch: refetchProjects
    } = useQuery(['projects'], () => {
        let values = filterForm.getFieldsValue();
        let q = {
            'company_id': values.company_id
        }
        return Project.getAll({q});
    }, {
        initialData: []
    });

    const {
        data: repositories,
        isLoading: repositoriesIsLoading,
        refetch: refetchRepositories
    } = useQuery(['repositories'], () => {
        let values = filterForm.getFieldsValue();
        let q = {
            'project_id': values.project_id
        }
        return Repository.getAll({q});
    }, {
        initialData: []
    });

    const [repositoryDrawerVisible, setRepositoryDrawerVisible] = useState(false);
    const [repositoryIdToEdit, setRepositoryIdToEdit] = useState(null);

    const columns = [
        {
            title: 'Nom',
            dataIndex: 'name'
        },
        {
            key: 'action',
            width: 80,
            render: (_, record) => (
                <Space>
                    <Button shape="circle" icon={<EyeOutlined/>}
                            onClick={() => onEditRepositoryHandler(record._id)}/>
                </Space>
            ),
        },
    ];

    let onEditRepositoryHandler = (companyId) => {
        setRepositoryIdToEdit(companyId);
        setRepositoryDrawerVisible(true);
    }

    let onClickAddRepositoryHandler = (e) => {
        setRepositoryDrawerVisible(true);
    }

    let onSaveRepositoryDrawerHandler = () => {
        refetchRepositories();
        closeRepositoryDrawer();
    }

    let onCloseRepositoryDrawerHandler = () => {
        closeRepositoryDrawer();
    }

    let closeRepositoryDrawer = () => {
        setRepositoryIdToEdit(null);
        setRepositoryDrawerVisible(false);
    }

    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Projets"
                extra={[
                    <Button shape="circle" icon={<PlusCircleOutlined/>} type="primary"
                            onClick={onClickAddRepositoryHandler}/>
                ]}
            />

            <div style={{
                maxWidth: "600px"
            }}>
                <Row>
                    <Col span={24}>
                        <Form
                            labelCol={{span: 4}}
                            layout="vertical"
                            size={'small'}
                            form={filterForm}
                        >
                            <Form.Item
                                label="Entreprise"
                                name="company_id"
                            >
                                <Select
                                    showSearch
                                    placeholder="Sélectionnez une entreprise"
                                    optionFilterProp="children"
                                    onChange={refetchProjects}
                                    filterOption={(input, option) =>
                                        (option.children.toLowerCase().includes(input.toLowerCase()))
                                    }
                                >
                                    {companies.map(company => (
                                        <Option value={company._id}>{company.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Projets"
                                name="project_id"
                            >
                                <Select
                                    disabled={projects.length == 0}
                                    showSearch
                                    placeholder="Sélectionnez un projet"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option.children.toLowerCase().includes(input.toLowerCase()))
                                    }
                                >
                                    {projects.map(project => (
                                        <Option value={project._id}>{project.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" onClick={refetchRepositories}>Filtrer</Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>

            <Table columns={columns} dataSource={repositories} loading={repositoriesIsLoading}/>

            <RepositoryDrawer visible={repositoryDrawerVisible} onClose={onCloseRepositoryDrawerHandler}
                              repositoryId={repositoryIdToEdit}
                              onSave={onSaveRepositoryDrawerHandler}/>
        </>
    )
}

export default Repositories;

