/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
import {Button, Drawer, Form, Input, Select, Space} from "antd";
import {useEffect} from "react";
import {User} from "../api/user";
import {useQuery} from "@tanstack/react-query";
import {Company} from "../api/company";

const {Option} = Select;

function UserDrawer(props) {

    const {data: compagnies, isLoading: compagniesIsLoading} = useQuery(['compagnies'], Company.getAll, {
        initialData: []
    });

    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
        if (props.userId) {
            User.getOne(props.userId).then(user => {
                form.setFieldsValue({
                    firstname: user.firstname,
                    lastname: user.lastname,
                    email: user.email,
                })
            });
        }
    }, [props.visible, props.userId])

    const onSaveDrawerHandler = () => {
        let payload = form.getFieldsValue();

        if (!props.userId || props.userId === null) {
            User.create(payload).then(() => {
                props.onSave();
            });
        } else {
            User.update(props.userId, payload).then(() => {
                props.onSave();
            });
        }
    }

    const title = () => {
        if (!props.userId || props.userId === null) {
            return "Ajout d'un nouveau utilisateur'";
        } else {
            return "Édition d'un utilisateur";
        }
    }

    const formItemRules = [
        {
            required: true,
            message: 'Champ obligatoire',
        }
    ]

    return (
        <>
            <Drawer title={title()} placement="left" onClose={props.onClose}
                    visible={props.visible} width={700} extra={
                <Space>
                    <Button onClick={props.onClose}>Annuler</Button>
                    <Button onClick={onSaveDrawerHandler} type="primary">
                        Enregistrer
                    </Button>
                </Space>
            }>
                <Form
                    form={form}
                    layout="vertical"
                >

                    <Form.Item name="company_id" label="Entreprise" rules={formItemRules} tooltip="This is a required field">
                        <Select
                            showSearch
                            placeholder="Sélectionnez une entreprise"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option.children.toLowerCase().includes(input.toLowerCase()))
                            }
                        >
                            {compagnies.map(company => (
                                <Option value={company._id}>{company.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="firstname" label="Prénom" required tooltip="This is a required field">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="lastname" label="Nom" required tooltip="This is a required field">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="email" label="Email" required tooltip="This is a required field">
                        <Input/>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    )
}

export default UserDrawer;
