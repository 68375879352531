import {Alert, Col, PageHeader, Row} from "antd";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function Variables() {
    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Customisation"
            />
            <Row>
                <Col span={24}>
                    <Alert style={{
                        marginBottom: "25px"
                    }}
                           message={"Bientôt disponible"}
                           description={'Outils "no-code" de personnalisation du projet'} type="info" showIcon />
                </Col>
            </Row>
        </>
    )
}

export default Variables;

