import {Config} from "../config";
import {useAuth0} from "@auth0/auth0-react";
import {getHeaders} from "./auth";

let ProjectSignalReport = {

    /**
     *
     */
    getSignals: async (reportId) => {
        let url = Config.apiUrl + '/project/report/' + reportId + '/signals';

        return fetch(url, {
            headers: getHeaders(),
        }).then(res => res.json())
    },
}

export {ProjectSignalReport};
