/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
import {Button, Drawer, Form, Input, Select, Space} from "antd";
import {useEffect} from "react";
import {Project} from "../api/project";
import {useQuery} from "@tanstack/react-query";
import {Company} from "../api/company";
import {Repository} from "../api/repository";

const {Option} = Select;

const manifestProviders = [{
    'value': 'composer',
    'label': 'composer',
}, {
    'value': 'pip',
    'label': 'pip',
}, {
    'value': 'node',
    'label': 'node',
}]

function RepositoryDrawer(props) {

    const [form] = Form.useForm();

    const {data: compagnies, isLoading: compagniesIsLoading} = useQuery(['compagnies'], Company.getAll, {
        initialData: []
    });

    const {
        data: projects,
        isLoading: projectsIsLoading,
        refetch: refetchProjects
    } = useQuery(['projects'], () => {
        let q = {};
        let values = form.getFieldsValue();

        return Project.getAll({q: values});
    }, {
        initialData: []
    });

    useEffect(() => {
        form.resetFields();
        if (props.repositoryId) {
            Repository.getOne(props.repositoryId).then(repository => {
                form.setFieldsValue({
                    name: repository.name,
                    project_id: repository.project_id,
                    git_url: repository.git_url,
                    manifest_path: repository.manifest_path,
                    manifest_provider: repository.manifest_provider,
                    gitlab_personal_access_token: repository.gitlab_personal_access_token,
                })
            });
        }
    }, [props.visible, props.repositoryId])

    const onSaveDrawerHandler = async () => {
        let payload = form.getFieldsValue();

        let isValid = await form.validateFields();

        if (isValid) {
            if (!props.repositoryId || props.repositoryId === null) {
                Repository.create(payload).then(() => {
                    props.onSave();
                });
            } else {
                Repository.update(props.repositoryId, payload).then(() => {
                    props.onSave();
                });
            }
        }
    }

    const title = () => {
        if (!props.repositoryId || props.repositoryId === null) {
            return "Ajout d'un nouveau dépôt";
        } else {
            return "Édition d'un dépôt";
        }
    }

    const formItemRules = [
        {
            required: true,
            message: 'Champ obligatoire',
        }
    ]

    return (
        <>
            <Drawer title={title()} placement="left" onClose={props.onClose}
                    visible={props.visible} width={700} extra={
                <Space>
                    <Button onClick={props.onClose}>Annuler</Button>
                    <Button onClick={onSaveDrawerHandler} type="primary">
                        Enregistrer
                    </Button>
                </Space>
            }>
                <Form
                    form={form}
                    layout="vertical"
                >
                    {
                        props.repositoryId === null ?
                            <Form.Item name="company_id" label="Entreprise" rules={formItemRules}
                                       tooltip="This is a required field">
                                <Select
                                    showSearch
                                    placeholder="Sélectionnez une entreprise"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option.children.toLowerCase().includes(input.toLowerCase()))
                                    }
                                    onChange={refetchProjects}
                                >
                                    {compagnies.map(company => (
                                        <Option value={company._id}>{company.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item> : null
                    }

                    <Form.Item name="project_id" label="Projet" rules={formItemRules}
                               tooltip="This is a required field">
                        <Select
                            showSearch
                            disabled={projects.length == 0}
                            placeholder="Sélectionnez un projet"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option.children.toLowerCase().includes(input.toLowerCase()))
                            }
                        >
                            {projects.map(projet => (
                                <Option value={projet._id}>{projet.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="name" label="Nom" rules={formItemRules} tooltip="This is a required field">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="git_url" label="Url Git" rules={formItemRules} tooltip="This is a required field">
                        <Input placeholder={"ex: https://gitlab.com/fiere-allure-studio/consultimo/consultimo.git"}/>
                    </Form.Item>
                    <Form.Item name="manifest_path" label="Chemin vers le manifest" rules={formItemRules}
                               tooltip="This is a required field">
                        <Input placeholder={"ex: /src"}/>
                    </Form.Item>
                    <Form.Item name="manifest_provider" label="Type de provider" rules={formItemRules}
                               tooltip="This is a required field">
                        <Select
                        >
                            {manifestProviders.map(provider => (
                                <Option value={provider.value}>{provider.label}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="gitlab_personal_access_token" label="Personal access token (Gitlab)"
                               rules={formItemRules} tooltip="This is a required field">
                        <Input/>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    )
}

export default RepositoryDrawer;
