import {Button, PageHeader, Space, Table} from "antd";
import {Company} from "../../api/company";
import {useQuery} from "@tanstack/react-query";
import {useState} from "react";

import {
    PlusCircleOutlined,
    EyeOutlined
} from "@ant-design/icons";
import CompanyDrawer from "../../components/companyDrawer";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function Compagnies() {
    const {data: compagnies, isLoading: compagniesIsLoading, refetch} = useQuery(['compagnies'], Company.getAll);
    const [companyDrawerVisible, setCompanyDrawerVisible] = useState(false);
    const [companyIdToEdit, setCompanyIdToEdit] = useState(null);

    const columns = [
        {
            title: 'Nom',
            dataIndex: 'name'
        },
        {
            key: 'action',
            width: 80,
            render: (_, record) => (
                <Space>
                    <Button shape="circle" icon={<EyeOutlined/>}
                            onClick={() => onEditCompanyHandler(record._id)}/>
                </Space>
            ),
        },
    ];

    let onEditCompanyHandler = (companyId) => {
        setCompanyIdToEdit(companyId);
        setCompanyDrawerVisible(true);
    }

    let onClickAddCompanyHandler = (e) => {
        setCompanyDrawerVisible(true);
    }

    let onSaveCompanyDrawerHandler = () => {
        refetch();
        closeCompanyDrawer();
    }

    let onCloseCompanyDrawerHandler = () => {
        closeCompanyDrawer();
    }

    let closeCompanyDrawer = () => {
        setCompanyIdToEdit(null);
        setCompanyDrawerVisible(false);
    }

    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Entreprises"
                extra={[
                    <Button shape="circle" icon={<PlusCircleOutlined/>} type="primary"
                            onClick={onClickAddCompanyHandler}/>
                ]}
            />

            <Table columns={columns} dataSource={compagnies} loading={compagniesIsLoading}/>

            <CompanyDrawer visible={companyDrawerVisible} onClose={onCloseCompanyDrawerHandler}
                           companyId={companyIdToEdit}
                           onSave={onSaveCompanyDrawerHandler}/>
        </>
    )
}

export default Compagnies;

