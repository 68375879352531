import {Alert, Col, List, PageHeader, Row, Statistic} from "antd";
import {CloseCircleOutlined, ExclamationCircleOutlined, LikeOutlined} from "@ant-design/icons";
import {useContext, useEffect, useState} from "react";
import {ProjectReport} from "../../api/projectReport";
import {ProjectSignalReport} from "../../api/projectSignalReport";
import {GlobalReport} from "../../api/globalReport";


function SignalsList(props) {
    return (
        <List
            loading={props.loading}
            bordered
            size={"small"}
            header={props.header}
            dataSource={props.signals}
            renderItem={(item) => (
                <List.Item>
                    <Row>
                        <Col style={{width: "30px"}}><ExclamationCircleOutlined style={{color: "#faad14"}}/></Col>
                        <Col>{item}</Col>
                    </Row>
                </List.Item>
            )}
        />
    )
}

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function Home() {

    const [loading, setLoading] = useState(false);
    const [signals, setSignals] = useState([]);
    const [totalRepositories, setTotalRepositories] = useState(0);
    const [totalProjects, setTotalProjects] = useState(0);

    useEffect(() => {
        setLoading(true);
        GlobalReport.getOne()
            .then((reports) => {
                let projectSignals = []
                let totalRepositories = 0;
                let totalProjects = 0;

                for (const report of reports) {
                    if (report['signals'].length > 0) {
                        projectSignals.push(`Des signaux pour le projet "${report['project']['name']}" ont été remontés et méritent une attention`)
                    }
                    totalRepositories += report['total_repositories'];
                    totalProjects += report['total_projects'];
                }

                setSignals(projectSignals);
                setLoading(false);
                setTotalRepositories(totalRepositories);
                setTotalProjects(totalProjects);
            })
            .catch(() => {
                setLoading(false);
            });
    }, [])

    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Accueil"
            />

            <Alert style={{
                marginBottom: "25px"
            }} message="Vue générale sur tous vos projets, liste des dernieres recommandations ou observations, et des derniers évènements." type="info" showIcon />

            <Row gutter={16} style={{
                marginBottom: "25px"
            }}>
                <Col span={6}>
                    <Statistic title="Projets" value={totalProjects} />
                </Col>
                <Col span={6}>
                    <Statistic title="Dépôts" value={totalRepositories} />
                </Col>
            </Row>

            <SignalsList loading={loading} signals={signals} header={<div>Synthèse des différents signaux, tous projets confondus.</div>} principal/>
        </>
    )
}

export default Home;

