import {Config} from "../config";
import {getHeaders} from "./auth";


let Repository = {

    /**
     *
     */
    getOne: async (companyId) => {
        let url = Config.apiUrl + '/repository/' + companyId;
        return fetch(url, {
            headers: getHeaders()
        }).then(res => res.json())
    },

    /**
     *
     */
    create: async (payload) => {
        let url = Config.apiUrl + '/repository';
        return fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: getHeaders(),
            body: JSON.stringify(payload)
        }).then(res => res.json())
    },

    /**
     *
     */
    update: async (companyId, payload) => {
        let url = Config.apiUrl + '/repository/' + companyId;
        return fetch(url, {
            method: 'PUT',
            credentials: 'include',
            headers: getHeaders(),
            body: JSON.stringify(payload)
        }).then(res => res.json())
    },

    /**
     *
     */
    getAll: async (payload) => {
        let url = Config.apiUrl + '/repositories';
        return fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: getHeaders(),
            body: JSON.stringify(payload)
        }).then(res => res.json())
    },

}

export {Repository};
