import {Config} from "../config";
import {getHeaders} from "./auth";

let RepositoryMetric = {

    /**
     *
     */
    getLast: async (repositoryId) => {
        let url = Config.apiUrl + '/repository/' + repositoryId + '/metrics';
        return fetch(url, {
            headers: getHeaders()
        }).then(res => res.json())
    },
}

export {RepositoryMetric};
