

function getHeaders() {
    return new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + localStorage.getItem('access_token')
    });
}

export {getHeaders};
