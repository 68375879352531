import {Alert, PageHeader, Timeline} from "antd";
import {useContext, useEffect, useState} from "react";
import {ProjectEvent} from "../../api/projectEvent";
import {SelectedProjectContext} from "../../App";
import {DateTime} from "luxon";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function TimelinePage() {
    const {selectedProject, updateSelectedProject} = useContext(SelectedProjectContext);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (selectedProject) {
            ProjectEvent.getAll(selectedProject._id)
                .then((events) => {
                    setEvents(events);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    }, [selectedProject])

    const buildMessage = (e) => {
        if (e.type == 'new_project') {
            return ('Création du projet')
        } else if (e.type == 'new_project_repo') {
            return ("Création d'un nouveau dépôt")
        } else if (e.type == 'new_project_report') {
            return ("Nouvelle analyse des dépôts")
        }
    }

    // Doit permettre de filtre selon le type d'event par exemple pour se concentrer en priorité sur le changelog

    let timeline = null;
    if (events.length > 0) {
        timeline = (
            <Timeline mode={'left'}>
                {events.map((e) => (
                    <Timeline.Item label={DateTime.fromISO(e['created_at']).toLocaleString(DateTime.DATETIME_FULL)}>{buildMessage(e)}</Timeline.Item>
                ))}
            </Timeline>
        );
    }

    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Timeline"
            />

            <Alert style={{
                marginBottom: "25px"
            }} message="Timeline de suivi de la vie du projet : Changelog (monté en version ou non), ticket support, déploiement, commandes de nouveaux dev." type="info" showIcon />

            {timeline}
        </>
    )
}

export default TimelinePage;

