import './assets/App.css';
import 'animate.css/animate.css';

import {
    Button, Card,
    Layout,
    Menu, Select,
    Tooltip
} from "antd";
import {Content, Footer, Header} from "antd/lib/layout/layout";
import {
    HomeOutlined,
    PlusOutlined,
    AudioOutlined,
    SecurityScanOutlined,
    CodepenOutlined,
    SettingOutlined,
    RightCircleOutlined,
    SnippetsOutlined,
    FileTextOutlined,
} from '@ant-design/icons';

import Sider from "antd/es/layout/Sider";
import logo from './assets/farrago_logotype_B.png';
import {
    BrowserRouter as Router, Link,
    Route,
    Routes,
    useNavigate
} from "react-router-dom";

import Home from "./pages/home/Home";
import Compagnies from "./pages/companies/Compagnies";
import {
    QueryClient,
    QueryClientProvider, useQuery,
} from '@tanstack/react-query'
import Projects from "./pages/projects/Projects";
import Repositories from "./pages/repositories/Repositories";
import Support from "./pages/support/Support";
import Observabilites from "./pages/observabilities/Observabilites";
import TimelinePage from "./pages/timeline/TimelinePage";
import Variables from "./pages/variables/Variables";
import {Project} from "./api/project";
import {createContext, useContext, useEffect, useState} from "react";
import Summary from "./pages/summary/Summary";
import {Auth0Provider, useAuth0} from "@auth0/auth0-react";
import Login from "./pages/login/Login";
import Users from "./pages/users/Users";
import {User} from "./api/user";

import {Config} from "./config";

const {Option} = Select;

// Create a client
const queryClient = new QueryClient();
export const SelectedProjectContext = createContext('');
export const CurrentUserContext = createContext('');


function SelectedProjectContexteProvider({children}) {
    const [selectedProject, setSelectedProject] = useState(null);

    const updateSelectedProject = (value) => {
        setSelectedProject(value);
    };

    return (
        <SelectedProjectContext.Provider value={{selectedProject, updateSelectedProject}}>
            {children}
        </SelectedProjectContext.Provider>
    );
}

function CurrentUserContexteProvider({children}) {
    const [currentUser, setCurrentUser] = useState(null);

    const updateCurrentUser = (value) => {
        setCurrentUser(value);
    };

    return (
        <CurrentUserContext.Provider value={{currentUser, updateCurrentUser}}>
            {children}
        </CurrentUserContext.Provider>
    );
}

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function AppLayout(props) {

    return (
        <div>
            <QueryClientProvider client={queryClient}>
                <CurrentUserContexteProvider>
                    <SelectedProjectContexteProvider>
                        <Layout style={{minHeight: '100vh'}}>
                            <AppSidebar/>
                            <Layout>
                                <AppHeader/>
                                <Content style={{margin: '24px 16px 0'}}>
                                    <div className="site-layout-background"
                                         style={{padding: '0 24px 24px 24px', minHeight: 360}}>
                                        {props.children}
                                    </div>
                                </Content>
                                <Footer style={{textAlign: 'center'}}>Minimin</Footer>
                            </Layout>
                        </Layout>
                    </SelectedProjectContexteProvider>
                </CurrentUserContexteProvider>
            </QueryClientProvider>
        </div>
    )
}

function AppSidebar() {
    const {selectedProject, updateSelectedProject} = useContext(SelectedProjectContext);
    const {currentUser, updateCurrentUser} = useContext(CurrentUserContext);

    let adminMenu = '';
    if (currentUser && currentUser['roles'].includes(Config.admin_role)) {
        adminMenu = (
            <Menu.SubMenu title="Administration" icon={<SettingOutlined/>}>
                <Menu.Item key="41">
                    <Link to={"/admin/companies"}>Entreprises</Link>
                </Menu.Item>
                <Menu.Item key="42">
                    <Link to={"/admin/users"}>Utilisateurs</Link>
                </Menu.Item>
                <Menu.Item key="43">
                    <Link to={"/admin/projects"}>Projets</Link>
                </Menu.Item>
                <Menu.Item key="44">
                    <Link to={"/admin/repositories"}>Dépôts</Link>
                </Menu.Item>
            </Menu.SubMenu>
        )
    }

    return (

        <Sider
            width={"300px"}
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={broken => {
            }}
            onCollapse={(collapsed, type) => {
            }}
        >
            <div className="logo"/>
            <div className="logo" style={{
                textAlign: "center",
                transition: "all 10ms ease",
                width: '80%',
                margin: "0 auto"
            }}>
                <img alt={'logo'} src={logo}/>
            </div>
            <Menu theme="dark" mode="inline" defaultSelectedKeys={['4']}>
                <Menu.Item key="1" icon={<HomeOutlined/>}>
                    <Link to={"/"}>Synthèse</Link>
                </Menu.Item>
                <Menu.Item key="2" icon={<AudioOutlined/>}>
                    <Link to={"/support"}>Support</Link>
                </Menu.Item>
                <Menu.SubMenu title={selectedProject ? "Projet, " + selectedProject.name : '-'}
                              icon={<RightCircleOutlined/>}>
                    <Menu.Item key="31" icon={<SnippetsOutlined/>}>
                        <Link to={"/timeline"}>Carnet de suivi</Link>
                    </Menu.Item>
                    <Menu.Item key="32" icon={<FileTextOutlined/>}>
                        <Link to={"/summary"}>Synthèse</Link>
                    </Menu.Item>
                    <Menu.Item key="33" icon={<SecurityScanOutlined/>}>
                        <Link to={"/observabilities"}>Observabilités</Link>
                    </Menu.Item>
                    <Menu.Item key="34" icon={<CodepenOutlined/>}>
                        <Link to={"/variables"}>Customisation</Link>
                    </Menu.Item>
                </Menu.SubMenu>
                {adminMenu}
            </Menu>
        </Sider>
    )
}

function AppHeader() {

    const {selectedProject, updateSelectedProject} = useContext(SelectedProjectContext);
    const {currentUser, updateCurrentUser} = useContext(CurrentUserContext);

    const {
        data: projects,
        isLoading: projectsIsLoading,
    } = useQuery(['projects'], () => {
        return Project.getAll({q: {}});
    }, {
        initialData: []
    });

    useEffect(() => {
        User.me().then(user => updateCurrentUser(user))
    }, [])

    useEffect(() => {
        if (selectedProject === null && projects.length > 0) {
            updateSelectedProject(projects[0]);
        }
    }, [projects])

    return (
        <Header className="site-layout-sub-header-background">
            Mon projet, <Select
                style={{
                    "width": "300px"
                }}
                showSearch
                disabled={projects.length == 0}
                placeholder="Sélectionnez un projet"
                optionFilterProp="children"
                value={selectedProject ? selectedProject._id : null}
                filterOption={(input, option) =>
                    (option.children.toLowerCase().includes(input.toLowerCase()))
                }
                onChange={(value) => {
                    Project.getOne(value).then((r) => {
                        updateSelectedProject(r);
                    });
                }}
            >
                {projects.map(projet => (
                    <Option value={projet._id}>{projet.name}</Option>
                ))}
            </Select>
        </Header>
    )
}

function SplashScreen() {
    return (<>
        <div style={{
            display: "block",
            backgroundColor: "#001529",
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
        }}>
            <div style={{ width: 500, margin: "150px auto", borderRadius: "32px" }}>
                <div >
                    <div className="logo" style={{
                        textAlign: "center",
                        transition: "all 10ms ease",
                        margin: "0 auto",
                        width: "100%"
                    }}>
                        <img alt={'logo'} src={logo}/>
                    </div>
                </div>
                <div style={{
                    textAlign: "center",
                    color: "white",
                    fontSize: "26px"
                }}>
                    Chargement ...
                </div>
            </div>
        </div>
    </>)
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({children, ...rest}) {
    const [loading, setLoading] = useState(true);

    const {user, isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            navigate('/login');
        } else {
            getAccessTokenSilently().then((token => {
                localStorage.setItem('access_token', token);
            }))
        }
    }, [isAuthenticated, isLoading])

    useEffect(() => {
        const interval = setTimeout(() => {
            setLoading(false);
        }, 3000);

        return () => clearTimeout(interval);
    }, []);

    if (loading || isLoading) {
        return (<SplashScreen/>)
    }

    return !rest.withoutLayout ? (
        <AppLayout>{children}</AppLayout>
    ) : children;
}

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const App = () => (
    <Auth0Provider domain={Config.auth0.domain}
                   clientId={Config.auth0.clientId}
                   authorizationParams={{
                       audience: Config.auth0.audience,
                       redirect_uri: window.location.origin
                   }}>

        <Router>
            <div className="App">
                <Routes>
                    <Route path="/"
                           element={
                               <PrivateRoute>
                                   <Home/>
                               </PrivateRoute>
                           }
                    />
                    <Route path="/login"
                           element={
                               <Login/>
                           }
                    />
                    <Route path="/support"
                           element={
                               <PrivateRoute>
                                   <Support/>
                               </PrivateRoute>
                           }
                    />
                    <Route path="/variables"
                           element={
                               <PrivateRoute>
                                   <Variables/>
                               </PrivateRoute>
                           }
                    />
                    <Route path="/timeline"
                           element={
                               <PrivateRoute>
                                   <TimelinePage/>
                               </PrivateRoute>
                           }
                    />
                    <Route path="/observabilities"
                           element={
                               <PrivateRoute>
                                   <Observabilites/>
                               </PrivateRoute>
                           }
                    />
                    <Route path="/summary"
                           element={
                               <PrivateRoute>
                                   <Summary/>
                               </PrivateRoute>
                           }
                    />
                    <Route path="/admin/users"
                           element={
                               <PrivateRoute>
                                   <Users/>
                               </PrivateRoute>
                           }
                    />
                    <Route path="/admin/companies"
                           element={
                               <PrivateRoute>
                                   <Compagnies/>
                               </PrivateRoute>
                           }
                    />
                    <Route path="/admin/projects"
                           element={
                               <PrivateRoute>
                                   <Projects/>
                               </PrivateRoute>
                           }
                    />
                    <Route path="/admin/repositories"
                           element={
                               <PrivateRoute>
                                   <Repositories/>
                               </PrivateRoute>
                           }
                    />
                </Routes>
            </div>
        </Router>
    </Auth0Provider>
);

export default App;

