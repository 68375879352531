import {Button, Card, Col, PageHeader, Row} from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import logo from "../../assets/farrago_logotype_B.png";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function Login() {
    const { loginWithRedirect } = useAuth0();

    return (
        <>
            <Row>
                <Col span={24}>
                    <Card style={{ width: 500, backgroundColor: "#001529", margin: "50px auto", borderRadius: "32px" }}>
                        <div >
                            <div className="logo" style={{
                                textAlign: "center",
                                transition: "all 10ms ease",
                                margin: "0 auto",
                                width: "100%"
                            }}>
                                <img alt={'logo'} src={logo}/>
                            </div>
                        </div>
                        <div style={{
                            textAlign: "center"
                        }}>
                            <Button onClick={loginWithRedirect} shape="round" size={'large'}>Se connecter</Button>
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Login;

