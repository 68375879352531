import {Config} from "../config";
import {useAuth0} from "@auth0/auth0-react";
import {getHeaders} from "./auth";

let GlobalReport = {

    /**
     *
     */
    getOne: async () => {
        let url = Config.apiUrl + '/report';

        return fetch(url, {
            headers: getHeaders(),
        }).then(res => res.json())
    },
}

export {GlobalReport};
