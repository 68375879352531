export const Config = {
    apiUrl: 'https://api.farrago.fr',
    appUrl: 'https://minimin.farrago.fr',
    auth0: {
        domain: "minimin.eu.auth0.com",
        clientId: "gQIJc1c8wIC8H40Ji7h9UtXaNQCDghlP",
        audience: 'https://minimin.eu.auth0.com/api/v2/'
    },
    admin_role: 'rol_DoamccTSGoywf5Ov'
};
