import {Alert, Col, List, PageHeader, Row, Tag} from "antd";
import {CloseCircleOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import {useContext, useEffect, useState} from "react";
import {RepositoryReport} from "../../api/repositoryReport";
import {ProjectSignalReport} from "../../api/projectSignalReport";
import {SelectedProjectContext} from "../../App";
import {DateTime} from "luxon";
import {ProjectReport} from "../../api/projectReport";


function SignalsList(props) {
    return (
        <List
            loading={props.loading}
            bordered
            size={"small"}
            header={props.header}
            dataSource={props.signals}
            renderItem={(item) => (
                <List.Item>
                    <Row>
                        <Col style={{width: "30px"}}>{item.type === 'danger' ?
                            <CloseCircleOutlined style={{color: "#ff4d4f"}}/> :
                            <ExclamationCircleOutlined style={{color: "#faad14"}}/>}</Col>
                        <Col>{item.message}</Col>
                    </Row>
                </List.Item>
            )}
        />
    )
}

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function Summary() {
    const {selectedProject, updateSelectedProject} = useContext(SelectedProjectContext);

    const [loading, setLoading] = useState(false);
    const [signals, setSignals] = useState([]);
    const [projectReport, setProjectReport] = useState([]);

    useEffect(() => {
        if (selectedProject) {
            setLoading(true);
            ProjectReport.getOne(selectedProject._id)
                .then((report) => {
                    setProjectReport(report);
                    return ProjectSignalReport.getSignals(report._id);
                })
                .then((signals) => {
                    setSignals(signals.signals);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    }, [selectedProject])

    const lastAnalyseDateFormat = () => {
        if (projectReport !== null) {
            return DateTime.fromISO(projectReport['created_at']).toLocaleString(DateTime.DATETIME_FULL)
        } else {
            return 'aucune'
        }
    }

    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Synthèse"
            />


            <Alert
                message="Synthèse du projet sur les signaux et les chiffres clés."
                type="info"
                showIcon
            />

            <Row>
                <Col style={{
                    textAlign: "right",
                    fontStyle: "italic"
                }} span={24}><p>Dernière analyse : {lastAnalyseDateFormat()}</p></Col>
            </Row>

            <SignalsList loading={loading} signals={signals} header={<div>Synthèse des différents signaux, de la dernière analyse, du projet.</div>} principal/>
        </>
    )
}

export default Summary;

