import {Button, Col, Form, Input, PageHeader, Row, Select, Space, Table} from "antd";
import {Project} from "../../api/project";
import {useQuery} from "@tanstack/react-query";
import {useState} from "react";

import {
    PlusCircleOutlined,
    EyeOutlined
} from "@ant-design/icons";
import ProjectDrawer from "../../components/projectDrawer";
import {useForm} from "antd/lib/form/Form";
import {Company} from "../../api/company";

const {Option} = Select;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function Projects() {
    const [filterForm] = useForm();

    const {
        data: companies,
        isLoading: companiesIsLoading
    } = useQuery(['companies'], Company.getAll, {
        initialData: []
    });

    const {
        data: projects,
        isLoading: projectsIsLoading,
        refetch: refetchProjects
    } = useQuery(['projects'], () => {
        let q = {};
        let values = filterForm.getFieldsValue();
        console.log(values)

        return Project.getAll({q: values});
    });

    const [projectDrawerVisible, setProjectDrawerVisible] = useState(false);
    const [projectIdToEdit, setProjectIdToEdit] = useState(null);

    const columns = [
        {
            title: 'Nom',
            dataIndex: 'name'
        },
        {
            key: 'action',
            width: 80,
            render: (_, record) => (
                <Space>
                    <Button shape="circle" icon={<EyeOutlined/>}
                            onClick={() => onEditProjectHandler(record._id)}/>
                </Space>
            ),
        },
    ];

    let onEditProjectHandler = (companyId) => {
        setProjectIdToEdit(companyId);
        setProjectDrawerVisible(true);
    }

    let onClickAddProjectHandler = (e) => {
        setProjectDrawerVisible(true);
    }

    let onSaveProjectDrawerHandler = () => {
        refetchProjects();
        closeProjectDrawer();
    }

    let onCloseProjectDrawerHandler = () => {
        closeProjectDrawer();
    }

    let closeProjectDrawer = () => {
        setProjectIdToEdit(null);
        setProjectDrawerVisible(false);
    }

    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Projets"
                extra={[
                    <Button shape="circle" icon={<PlusCircleOutlined/>} type="primary"
                            onClick={onClickAddProjectHandler}/>
                ]}
            />

            <div style={{
                maxWidth: "600px"
            }}>
                <Row>
                    <Col span={24}>
                        <Form
                            labelCol={{span: 4}}
                            layout="vertical"
                            size={'small'}
                            form={filterForm}
                        >
                            <Form.Item
                                label="Entreprise"
                                name="company_id"
                            >
                                <Select
                                    showSearch
                                    placeholder="Sélectionnez une entreprise"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option.children.toLowerCase().includes(input.toLowerCase()))
                                    }
                                >
                                    {companies.map(company => (
                                        <Option value={company._id}>{company.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" onClick={refetchProjects}>Filtrer</Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>

            <Table columns={columns} dataSource={projects} loading={projectsIsLoading}/>

            <ProjectDrawer visible={projectDrawerVisible} onClose={onCloseProjectDrawerHandler}
                           projectId={projectIdToEdit}
                           onSave={onSaveProjectDrawerHandler}/>
        </>
    )
}

export default Projects;

